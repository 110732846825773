@import './App.scss'; // quick hack, not sure if this import is a good idea (styles are also imported in .tsx)

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3f3f3f3f;
}

html {
  background: url(./assets/background1.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $ccc-color-bg;
  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: black;
    opacity: 0.6;
    transition: opacity 6s; // should be in line with fakeDelay in AppSettings
  }
  &.ccc-bright-background::after {
    opacity: 0.85;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.ccc-overflow-scroll {
    overflow-y: scroll;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ccc-margin {
  $base: 8px;
  $base-2: $base * 2;
  $base-3: $base * 3;

  &-top {
    margin-top: $base !important;
    &-2 {
      margin-top: $base-2 !important;
    }
    &-3 {
      margin-top: $base-3 !important;
    }
  }

  &-top-bottom {
    &-2 {
      margin-top: $base-2 !important;
      margin-bottom: $base-2 !important;
    }
  }
}

.salesRankIndicator .rc-progress-circle-trail {
  display: none;
}
