// from https://codepen.io/WebSonata/pen/bRaONB

$loader-outer-time: 2s;
$loader-middle-time: 1.5s;
$loader-inner-time: 1s;

.loader {
  padding: 80px 0;

  &-circles {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $ccc-color-primary;
    -webkit-animation: spin $loader-middle-time linear infinite;
    animation: spin $loader-middle-time linear infinite;
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $ccc-color-primary-light;
      -webkit-animation: spin $loader-outer-time linear infinite;
      animation: spin $loader-outer-time linear infinite;
    }
    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $ccc-color-primary-dark;
      -webkit-animation: spin $loader-inner-time linear infinite;
      animation: spin $loader-inner-time linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  &-text {
    margin: 20px 0;
  }
}
