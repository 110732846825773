@import './shared/styles/index';
@import './shared/components/index';
@import '~react-vis/dist/style';

@mixin ccc-content-width {
  max-width: 100%;
  margin: 0 auto 60px auto;
  box-sizing: border-box;
  @media only screen and (min-width: 960px) {
    max-width: 90%;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1400px;
  }
}

.App {
  text-align: center;
  color: white;
  min-height: calc(100vh - 50px);

  &-results {
    min-height: 200px;
    @include ccc-content-width();
    margin-bottom: 160px;
  }
  &-horizontal-section {
    color: $ccc-text-color-dark;
    background-color: $ccc-section-bg-color;
    padding: 60px 0;
    &-content {
      @include ccc-content-width();
    }
  }

  &-search-box {
    margin: 0 auto;
    padding: 40px 0;
    width: 400px;
    max-width: calc(100vw - 20px);
    &--centered {
      padding-top: 50vh;
      transform: translateY(-160px);

      .App-search-box {
        &-input {
          margin: 40px 0;
        }
      }
    }
    transition: 0.3s;
  }
}
