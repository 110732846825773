@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ccc-typography-lato {
  font-family: 'Lato', sans-serif !important; }

.loader {
  padding: 80px 0; }
  .loader-circles {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ff9000;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite; }
    .loader-circles:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #ffb74d;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite; }
    .loader-circles:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f57c00;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .loader-text {
    margin: 20px 0; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }

.rv-xy-plot canvas {
  pointer-events: none; }

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }

.rv-discrete-color-legend.horizontal {
  white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }

.rv-discrete-color-legend-item.horizontal {
  display: inline-block; }

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0 1; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }

.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%; }

.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom; }

.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

.App {
  text-align: center;
  color: white;
  min-height: calc(100vh - 50px); }
  .App-results {
    min-height: 200px;
    max-width: 100%;
    margin: 0 auto 60px auto;
    box-sizing: border-box;
    margin-bottom: 160px; }
    @media only screen and (min-width: 960px) {
      .App-results {
        max-width: 90%; } }
    @media only screen and (min-width: 1400px) {
      .App-results {
        max-width: 1400px; } }
  .App-horizontal-section {
    color: #282c34;
    background-color: #eff2f5;
    padding: 60px 0; }
    .App-horizontal-section-content {
      max-width: 100%;
      margin: 0 auto 60px auto;
      box-sizing: border-box; }
      @media only screen and (min-width: 960px) {
        .App-horizontal-section-content {
          max-width: 90%; } }
      @media only screen and (min-width: 1400px) {
        .App-horizontal-section-content {
          max-width: 1400px; } }
  .App-search-box {
    margin: 0 auto;
    padding: 40px 0;
    width: 400px;
    max-width: calc(100vw - 20px);
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .App-search-box--centered {
      padding-top: 50vh;
      -webkit-transform: translateY(-160px);
              transform: translateY(-160px); }
      .App-search-box--centered .App-search-box-input {
        margin: 40px 0; }

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5; }

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3f3f3f3f; }

html {
  background: url(/static/media/background1.e3a5e6bf.jpg) no-repeat center center fixed;
  background-size: cover;
  background-color: #282c34; }
  html::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: black;
    opacity: 0.6;
    -webkit-transition: opacity 6s;
    transition: opacity 6s; }
  html.ccc-bright-background::after {
    opacity: 0.85; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body.ccc-overflow-scroll {
    overflow-y: scroll; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.ccc-margin-top {
  margin-top: 8px !important; }
  .ccc-margin-top-2 {
    margin-top: 16px !important; }
  .ccc-margin-top-3 {
    margin-top: 24px !important; }

.ccc-margin-top-bottom-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.salesRankIndicator .rc-progress-circle-trail {
  display: none; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ccc-typography-lato {
  font-family: 'Lato', sans-serif !important; }

.loader {
  padding: 80px 0; }
  .loader-circles {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #ff9000;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite; }
    .loader-circles:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #ffb74d;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite; }
    .loader-circles:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #f57c00;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .loader-text {
    margin: 20px 0; }

.react-vis-magic-css-import-rule {
  display: inherit; }

.rv-treemap {
  font-size: 12px;
  position: relative; }

.rv-treemap__leaf {
  overflow: hidden;
  position: absolute; }

.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center; }

.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis; }

.rv-xy-plot {
  color: #c3c3c3;
  position: relative; }

.rv-xy-plot canvas {
  pointer-events: none; }

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute; }

.rv-xy-plot__inner {
  display: block; }

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9; }

.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px; }

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9; }

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9; }

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all; }

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px; }

.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none; }

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px; }

.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0; }

.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.rv-crosshair__inner--left {
  right: 4px; }

.rv-crosshair__inner--right {
  left: 4px; }

.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap; }

.rv-crosshair__item {
  white-space: nowrap; }

.rv-hint {
  position: absolute;
  pointer-events: none; }

.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap; }

.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px; }

.rv-discrete-color-legend.horizontal {
  white-space: nowrap; }

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px; }

.rv-discrete-color-legend-item.horizontal {
  display: inline-block; }

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block; }

.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible; }

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px; }

.rv-discrete-color-legend-item__title {
  margin-left: 10px; }

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8; }

.rv-discrete-color-legend-item.clickable {
  cursor: pointer; }

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9; }

.rv-search-wrapper {
  display: flex;
  flex-direction: column; }

.rv-search-wrapper__form {
  flex: 0 1; }

.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0; }

.rv-search-wrapper__contents {
  flex: 1 1;
  overflow: auto; }

.rv-continuous-color-legend {
  font-size: 12px; }

.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px; }

.rv-continuous-size-legend {
  font-size: 12px; }

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%; }

.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom; }

.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%; }

.rv-legend-titles {
  height: 16px;
  position: relative; }

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden; }

.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%; }

.rv-legend-titles__right {
  right: 0; }

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none; }

.App {
  text-align: center;
  color: white;
  min-height: calc(100vh - 50px); }
  .App-results {
    min-height: 200px;
    max-width: 100%;
    margin: 0 auto 60px auto;
    box-sizing: border-box;
    margin-bottom: 160px; }
    @media only screen and (min-width: 960px) {
      .App-results {
        max-width: 90%; } }
    @media only screen and (min-width: 1400px) {
      .App-results {
        max-width: 1400px; } }
  .App-horizontal-section {
    color: #282c34;
    background-color: #eff2f5;
    padding: 60px 0; }
    .App-horizontal-section-content {
      max-width: 100%;
      margin: 0 auto 60px auto;
      box-sizing: border-box; }
      @media only screen and (min-width: 960px) {
        .App-horizontal-section-content {
          max-width: 90%; } }
      @media only screen and (min-width: 1400px) {
        .App-horizontal-section-content {
          max-width: 1400px; } }
  .App-search-box {
    margin: 0 auto;
    padding: 40px 0;
    width: 400px;
    max-width: calc(100vw - 20px);
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .App-search-box--centered {
      padding-top: 50vh;
      -webkit-transform: translateY(-160px);
              transform: translateY(-160px); }
      .App-search-box--centered .App-search-box-input {
        margin: 40px 0; }

