// raw color values
$ccc-color-bg: #282c34;
$ccc-color-white: #fff;
$ccc-color-grey: #eff2f5;
// should be in line with colors.ts and theme.ts
$ccc-color-primary: #ff9000;
$ccc-color-primary-dark: #f57c00;
$ccc-color-primary-light: #ffb74d;

// concept color values
$ccc-text-color-dark: #282c34;

$ccc-section-bg-color: $ccc-color-grey;
